@import "compass";
@import "theme_variables";

$top-menu-border-bottom: 2px solid $med-border-color;

nav>#block_top_menu {
    @media (max-width: $screen-md) {
        display: none;
    }

    @media (min-width: $screen-md) {
        display: block;
    }
}

#top-container #block_top_menu {
    @media (max-width: $screen-md) {
        display: block;
    }

    @media (min-width: $screen-md) {
        display: none;
    }
}

#block_top_menu {

    margin: 0 auto;

    .sf-menu {
        margin: 0 auto;
        background-color: $box-background;
        border-bottom: $top-menu-border-bottom;
        text-align: center;

        @media (min-width: $screen-md) {
            background-color: unset;
            border-bottom: none;
        }

        @media (max-width: $screen-sm) {
            background-color: $dark-background;
        }


        >li {
            margin-bottom: -2px;
            border-right: none;
            float: none;
            display: inline-block;
            width: auto;
            padding: 0 10px;
            margin-right: 10px;


			@media (max-width: $screen-md) {
                display: block;
            }

            &.sfHoverForce>a {
                background-color: $box-background;
                border-bottom: 2px solid $theme-link;
                // color: #fff;
            }

            &.sfHover>a {
                border-bottom: 2px solid $theme-highlight;
                background-color: $box-background;
            }

            &:Hover>a {
                border-bottom: 2px solid $theme-highlight;
                background-color: $box-background;
                color: $theme-link;
            }

            >a {
                border-bottom: $top-menu-border-bottom;
                color: #eee;
                padding: 17px 20px;

                @media (min-width: $screen-md) {
                    border-bottom: none;
                }

                @media (max-width: $screen-lg) and (min-width: $screen-md) {
                    padding: 17px 5px;
                }
            }
            
            >.submenu-container {
                background-color: $med-border-color;
                @meida (min-width: $screen-md) {
                    top: 58px;
                    box-shadow: 0 4px 4px 1px rgba(0,0,0,0.3);
                }

                @media (max-width: $screen-md) {
                    top: 0px;
                }

                >li {

                    height: 30px;
                    line-height: 30px;

                    @media (min-width: $screen-sm) {
                        height: 40px;
                        line-height: initial;
                        margin-bottom: 30px;
                    }

                    @media (min-width: $screen-sm) {
                        float: none;
                        display: inline-block;
                    }
        

                    >a {
                        color: #ddd;

                        &:hover {
                            color: $theme-link;
                        }
                    }
                }

            }
            >ul {
                @media (min-width: $screen-md) {
                    width: 100vw;
                    left: 0px;
                }
            }
        }
    }
}
